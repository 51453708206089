export const languageOrdering = [
  "English",
  "Spanish",
  "Vietnamese",
  "Russian",
  "Ukrainian",
  "Tagalog",
  "Somali",
  "Korean",
  "Arabic",
  "Punjabi",
  "Cambodian",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Marshallese",
  "Samoan",
  "Hindi",
  "Amharic",
  "Japanese",
  "Telugu",
  "Urdu",
  "Lao",
  "Romanian",
  "Tigrinya",
  "Farsi",
  "Tamil",
  "French",
  "Nepali",
  "Hmong",
  "Chuukese",
  "Mixteco-Bajo",
  "Swahili",
  "German",
  "Burmese",
  "Thai",
  "Oromo",
  "Karen",
  "Portuguese",
  "Pashto",
];

export const languageCodeMapping = {
  Amharic: "amh",
  Arabic: "ar",
  Burmese: "my",
  Cambodian: "km",
  "Chinese (Simplified)": "zh_CN",
  "Chinese (Traditional)": "zh_TW",
  Chuukese: "chk",
  English: "en",
  Farsi: "fa",
  French: "fr",
  German: "de",
  Hindi: "hi",
  Hmong: "hmn",
  Japanese: "ja",
  Karen: "kar",
  Korean: "ko",
  Lao: "lo",
  Marshallese: "mh",
  "Mixteco-Bajo": "mix",
  Nepali: "ne",
  Oromo: "om",
  Pashto: "ps",
  Portuguese: "pt",
  Punjabi: "pa",
  Romanian: "ro",
  Russian: "ru",
  Samoan: "sm",
  Somali: "so",
  Spanish: "es",
  Swahili: "sw",
  Tagalog: "tl",
  Tamil: "ta",
  Telugu: "te",
  Thai: "th",
  Tigrinya: "ti",
  Ukrainian: "uk",
  Urdu: "ur",
  Vietnamese: "vi",
};
