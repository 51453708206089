import React, { useEffect } from "react";
import SearchModule from "../partials/search-module";
// import ComingSoon from '../partials/coming-soon';
import vacc_icon from "../images/shared/vac_icon.svg";
import who_should_test_img from "../images/shared/family_full.png";
import dig_assist_icon from "../images/shared/dig_assist_icon.svg";
import collage from "../images/shared/collage3.png";
import itap_logo from "../images/logos/itap-logo.svg";
import { useTranslation, Trans } from "react-i18next";

import TagManager from "react-gtm-module";

export default function Home() {
  const { t, i18n } = useTranslation();
  var daUrl =
    "https://safercovid.org/mytest/index.html?utm_source=website&lang=" +
    i18n.resolvedLanguage;

  const tagManagerArgs = {
    dataLayer: {
      event: "page_view",
      page_location: document.location.href,
      page_title: t("home-meta-title"),
    },
  };

  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    document.title = t("home-meta-title");
  }, []);
  return (
    <main className="home-page">
      <SearchModule />
      {/* <ComingSoon/> */}

      <section
        data-aos="zoom-y-out"
        className="highlighted collage why-say-yes thirty-70 col-2-sm"
      >
        <div className="section-image">
          <img src={collage} alt="people working happily" />
        </div>
        <div>
          <div className="section-text">
            <h2>{t("why-say-yes-h")}</h2>
            <ul>
              <li>{t("why-say-yes-01")}</li>
              <li>{t("why-say-yes-02")}</li>
              <li>{t("why-say-yes-03")}</li>
              <li>{t("why-say-yes-04")}</li>
            </ul>
          </div>
        </div>
      </section>

      <section data-aos="zoom-y-out" className="feature sixty-40 col-2-sm">
        <div className="section-text">
          <h2>{t("who-h")}</h2>
          <p>
            <strong>{t("who-01")}</strong> {t("who-02")}
          </p>
        </div>
        <div>
          <img src={who_should_test_img} alt="happy family" />
        </div>
      </section>
      <section
        data-aos="zoom-y-out"
        className="feature vaccinated thirty-70 col-2-sm dark-bg"
      >
        <div className="center">
          <img src={vacc_icon} alt="vaccination icon" />
        </div>
        <div className="section-text">
          <h2>{t("vacc-h")}</h2>
          <p>
            <Trans i18nKey="vacc-01">
              Vaccination, along with testing,
              <strong>is the best way of preventing COVID-19 spread</strong>.
              COVID-19 vaccines are safe and effective at preventing infections,
              hospitalizations, and deaths. However, even fully vaccinated
              people have a chance of becoming infected and spreading the virus,
              so <strong>testing remains important for everyone.</strong>
            </Trans>
          </p>
          <p>
            <Trans i18nKey="vacc-02">
              Anyone age 5 and older is now eligible for vaccination.
              <strong>
                COVID-19 vaccines are free of charge to everyone living in the
                US
              </strong>
              , regardless of immigration or health insurance status. Visit
              <a
                href="https://www.vaccines.gov/"
                target="_blank"
                rel="noreferrer"
              >
                vaccines.gov
              </a>
              to learn more.
            </Trans>
          </p>
        </div>
      </section>
      <section data-aos="zoom-y-out" className="feature sixty-40 col-2-sm">
        <div className="section-text">
          <h2>{t("dig-assist-h")}</h2>
          <p>{t("dig-assist-p")}</p>
        </div>
        <div className="instructions-button dig-assist">
          <a href={daUrl} target="_blank" rel="noreferrer">
            <img src={dig_assist_icon} alt="access the digital assistant" />
            <p>
              <strong>{t("dig-assist-button")}</strong>
            </p>
          </a>
        </div>
      </section>
      <section
        data-aos="zoom-y-out"
        className="want-to-help col-2-md-reverse sixty-40 highlighted"
      >
        <div className="section-text">
          <h2>{t("nih-h")}</h2>
          <Trans i18nKey="itap-01">
            <p>NIH study placeholder</p>
          </Trans>
        </div>
        <div data-aos="zoom-y-out" className="center">
          <p>
            <a
              href="https://covid19homestudies.org/itap"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ width: "300px" }}
                src={itap_logo}
                alt="ITAP Logo"
              />
            </a>
          </p>
          <div className="button">
            <a
              href="https://covid19homestudies.org/itap"
              target="_blank"
              rel="noreferrer"
            >
              {t("learn-about")}
            </a>
          </div>
        </div>
      </section>
    </main>
  );
}
