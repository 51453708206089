import React, { useEffect } from "react";
import SearchModule from "../partials/search-module";
// import ComingSoon from '../partials/coming-soon';
import Accordion from "../partials/accordion";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
export default function FAQ() {
  const { t } = useTranslation();

  const tagManagerArgs = {
    dataLayer: {
      event: "page_view",
      page_location: document.location.href,
      page_title: t("faq-meta-title"),
    },
  };

  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    document.title = t("faq-meta-title");
  }, []);

  const about = React.useRef();
  const kits = React.useRef();
  const shipment = React.useRef();
  const testing = React.useRef();
  const privacy = React.useRef();
  const digital = React.useRef();
  const other = React.useRef();

  /**
   * @param {HTMLElement} target
   */
  function smoothScroll(target) {
    const { top } = target.getBoundingClientRect();
    window.scrollTo({
      top: top + window.pageYOffset,
      behavior: "smooth",
    });
  }

  const panels_about = [
    {
      label: t("faq-about-01q"),
      content: t("faq-about-01a"),
    },
    {
      label: t("faq-about-02q"),
      content: t("faq-about-02a"),
    },
    {
      label: t("faq-about-03q"),
      content: t("faq-about-03a"),
    },
    {
      label: t("faq-about-04q"),
      content: t("faq-about-04a"),
    },
  ];

  const panels_kits = [
    {
      label: t("faq-kits-01q"),
      content: t("faq-kits-01a"),
    },
    {
      label: t("faq-kits-02q"),
      content: t("faq-kits-02a"),
    },
    {
      label: t("faq-kits-03q"),
      content: t("faq-kits-03a"),
    },
    {
      label: t("faq-kits-04q"),
      content: t("faq-kits-04a"),
    },
    {
      label: t("faq-kits-05q"),
      content: t("faq-kits-05a"),
    },
    {
      label: t("faq-kits-06q"),
      content: t("faq-kits-06a"),
    },
    {
      label: t("faq-kits-07q"),
      content: t("faq-kits-07a"),
    },
    {
      label: t("faq-kits-08q"),
      content: t("faq-kits-08a"),
    },
  ];
  const panels_shipment = [
    {
      label: t("faq-shipment-01q"),
      content: t("faq-shipment-01a"),
    },
    {
      label: t("faq-shipment-02q"),
      content: t("faq-shipment-02a"),
    },
  ];
  const panels_testing = [
    {
      label: t("faq-testing-00q"),
      content: t("faq-testing-00a"),
    },
    {
      label: t("faq-testing-01q"),
      content: t("faq-testing-01a"),
    },
    {
      label: t("faq-testing-02q"),
      content: t("faq-testing-02a"),
    },
    {
      label: t("faq-testing-03q"),
      content: t("faq-testing-03a"),
    },
    {
      label: t("faq-testing-04q"),
      content: t("faq-testing-04a"),
    },
    {
      label: t("faq-testing-05q"),
      content: t("faq-testing-05a"),
    },
    {
      label: t("faq-testing-06q"),
      content: t("faq-testing-06a"),
    },
    {
      label: t("faq-testing-07q"),
      content: t("faq-testing-07a"),
    },
    {
      label: t("faq-testing-08q"),
      content: t("faq-testing-08a"),
    },
    {
      label: t("faq-testing-09q"),
      content: t("faq-testing-09a"),
    },
    {
      label: t("faq-testing-10q"),
      content: t("faq-testing-10a"),
    },
    {
      label: t("faq-testing-11q"),
      content: t("faq-testing-11a"),
    },
  ];
  const panels_privacy = [
    {
      label: t("faq-privacy-01q"),
      content: t("faq-privacy-01a"),
    },
    {
      label: t("faq-privacy-02q"),
      content: t("faq-privacy-02a"),
    },
    {
      label: t("faq-privacy-03q"),
      content: t("faq-privacy-03a"),
    },
    {
      label: t("faq-privacy-04q"),
      content: t("faq-privacy-04a"),
    },
    {
      label: t("faq-privacy-05q"),
      content: t("faq-privacy-05a"),
    },
  ];
  const panels_digital = [
    {
      label: t("faq-digital-01q"),
      content: t("faq-digital-01a"),
    },
    {
      label: t("faq-digital-02q"),
      content: t("faq-digital-02a"),
    },
    {
      label: t("faq-digital-03q"),
      content: t("faq-digital-03a"),
    },
    {
      label: t("faq-digital-04q"),
      content: t("faq-digital-04a"),
    },
    {
      label: t("faq-digital-05q"),
      content: t("faq-digital-05a"),
    },
    {
      label: t("faq-digital-06q"),
      content: t("faq-digital-06a"),
    },
    {
      label: t("faq-digital-07q"),
      content: t("faq-digital-07a"),
    },
    {
      label: t("faq-digital-08q"),
      content: t("faq-digital-08a"),
    },
    {
      label: t("faq-digital-09q"),
      content: t("faq-digital-09a"),
    },
    {
      label: t("faq-digital-10q"),
      content: t("faq-digital-10a"),
    },
    {
      label: t("faq-digital-11q"),
      content: t("faq-digital-11a"),
    },
    {
      label: t("faq-digital-12q"),
      content: t("faq-digital-12a"),
    },
    {
      label: t("faq-digital-13q"),
      content: t("faq-digital-13a"),
    },
  ];
  const panels_other = process.env.REACT_APP_SITE_BRAND === "act" ? [
    { /*ACT Other FAQs*/
      label: t("faq-other-01q"),
      content: t("faq-other-01a"),
    },
  ] : [
    { /*SYCT Other FAQs*/
      label: t("faq-other-01q"),
      content: t("faq-other-01a"),
    },
    {
      label: t("faq-other-03q"),
      content: t("faq-other-03a"),
    },
  ];

  return (
    <>
      <main className="accordion faq-page">
        <section className="center intro">
          <h1>{t("faq-intro-h")}</h1>
          <p>{t("faq-intro-p")}</p>
          <p className="toc">
            <strong>
              <span onClick={() => smoothScroll(about.current)}>
                {t("about-the-program")}
              </span>
              |
              <span onClick={() => smoothScroll(kits.current)}>
                {t("test-kits")}
              </span>
              |
              <span onClick={() => smoothScroll(shipment.current)}>
                {t("shipment")}
              </span>
              |
              <span onClick={() => smoothScroll(testing.current)}>
                {t("testing")}
              </span>
              |
              <span onClick={() => smoothScroll(digital.current)}>
                {t("digital-assistant")}
              </span>
              |
              <span onClick={() => smoothScroll(privacy.current)}>
                {t("privacy")}
              </span>
              |
              <span onClick={() => smoothScroll(other.current)}>
                {t("other")}
              </span>
            </strong>
          </p>
        </section>

        <section className="faq-about" ref={about} data-aos="zoom-y-out">
          <h2 className="highlighted">{t("about-the-program")}</h2>
          <Accordion panels={panels_about} />
        </section>
        <section className="faq-kits" ref={kits} data-aos="zoom-y-out">
          <h2>{t("test-kits")}</h2>
          <Accordion panels={panels_kits} />
        </section>
        <section className="faq-shipment" ref={shipment} data-aos="zoom-y-out">
          <h2>{t("shipment")}</h2>
          <Accordion panels={panels_shipment} />
        </section>
        <section className="faq-testing" ref={testing} data-aos="zoom-y-out">
          <h2>{t("testing")}</h2>
          <Accordion panels={panels_testing} />
        </section>
        <section className="faq-digital" ref={digital} data-aos="zoom-y-out">
          <h2>{t("digital-assistant")}</h2>
          <Accordion panels={panels_digital} />
        </section>
        <section className="faq-privacy" ref={privacy} data-aos="zoom-y-out">
          <h2>{t("privacy")}</h2>
          <Accordion panels={panels_privacy} />
        </section>
        <section className="faq-other" ref={other} data-aos="zoom-y-out">
          <h2>{t("other")}</h2>
          <Accordion panels={panels_other} />
        </section>
      </main>
      <div className="highlighted">
        <SearchModule />
        {/* <ComingSoon/> */}
      </div>
    </>
  );
}
