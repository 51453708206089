import React from "react";

class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.panelWrapperRef = React.createRef();
    this.state = {
      height: 0,
    };
  }

  componentDidMount() {
    window.setTimeout(() => {
      const el = this.panelWrapperRef.current;
      if (!el) {
        return;
      }
      const height = el.querySelector(".panel__content").scrollHeight;
      this.setState({
        height,
      });
    }, 333);
  }

  render() {
    const { label, content, activeTab, index, activateTab } = this.props;
    const { height } = this.state;
    const isActive = activeTab === index;
    const innerStyle = {
      height: `${isActive ? height : 0}px`,
    };

    if (!label || !content) {
      return null;
    }

    return (
      <div
        className="panel"
        ref={this.panelWrapperRef}
        data-aos="zoom-y-out"
        data-aos-delay="250"
        // role='tabpanel'
        aria-expanded={isActive}
      >
        <button className="panel__label" role="tab" onClick={activateTab}>
          {label}
        </button>
        <div
          className="panel__inner"
          style={innerStyle}
          aria-hidden={!isActive}
        >
          <div
            className="panel__content text-gray-700"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
    );
  }
}

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
    };
    this.activateTab = this.activateTab.bind(this);
  }

  activateTab(index) {
    this.setState((prev) => ({
      activeTab: prev.activeTab === index ? -1 : index,
    }));
  }

  render() {
    const { panels } = this.props;
    const { activeTab } = this.state;
    return (
      <div className="accordion" role="tablist">
        {panels.map((panel, index) => (
          <Panel
            key={index}
            activeTab={activeTab}
            index={index}
            {...panel}
            activateTab={this.activateTab.bind(null, index)}
          />
        ))}
      </div>
    );
  }
}

export default Accordion;
