import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { routes } from "../routes";

import SYCTlogo from "../images/logos/syct_logo.svg";
import ACTlogo from "../images/logos/ProjectACT_Final_white.png";
import rock_logo from "../images/logos/rockefeller_white.png";
import LanguageSwitcher from "./language-switcher";

export default function Nav() {
  const { t, i18n } = useTranslation();
  //   TagManager.dataLayer(tagManagerArgs)
  //   useEffect(() => {
  //     document.title = docTitle;
  //   }, []);

  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleToggle = () => {
    setNavbarOpen((prev) => !prev);
  };

  return (
    <nav
      className={`main-navigation ${navbarOpen ? " toggled" : ""}`}
      role="navigation"
    >
      <div id="site-header">
        <Link
          to={generatePath(routes.root, { locale: i18n.resolvedLanguage })}
          rel="home"
        >
          {/* <SYCTlogo alt="Say Yes Logo Home Button" /> */}
          <img
            loading="lazy"
            className="site-logo"
            src={
              process.env.REACT_APP_SITE_BRAND === "act" ? ACTlogo : SYCTlogo
            }
            alt="site-logo"
          />
        </Link>
      </div>
      <div className="nav_rock_logo">
        <a
          href="https://www.rockefellerfoundation.org/report/a-resetting-of-americas-response-to-covid-19/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={rock_logo} alt="Rockefeller Foundation logo" />
        </a>
      </div>
      {process.env.REACT_APP_SITE_BRAND === "act" ? null : (
        <LanguageSwitcher stacked={true} />
      )}
      <div className="lang-n-pages">
        <LanguageSwitcher />

        <button
          onClick={handleToggle}
          id="nav-toggle"
          type="button"
          value="toggle mobile nav"
          title="mobile menu"
        >
          <span></span>
        </button>

        <ul className="menu">
          <li>
            <Link
              to={generatePath(routes.about, { locale: i18n.resolvedLanguage })}
            >
              {t("about-testing")}
            </Link>
          </li>
          <li>
            <Link
              to={generatePath(routes.faq, { locale: i18n.resolvedLanguage })}
            >
              {t("faq")}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
