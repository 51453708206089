import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  generatePath,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import "./styles/fontawesome/css/fontawesome.min.css";
import "./styles/fontawesome/css/solid.min.css";
import "./styles/_main.css";

import { routes, routesBase } from "./routes";

import Layout from "./pages/layout";
import Home from "./pages/home";
import FAQ from "./pages/faq";
import About from "./pages/about-the-tests";
import Disclaimer from "./pages/disclaimer";
// import { focusHandling } from 'cruip-js-toolkit';
import actFavicon from "./images/favicons/favicon-act.ico";
import syctnFavicon from "./images/favicons/favicon-syctn.ico";
import actFavicon192 from "./images/favicons/favicon-act-192.png";
import syctnFavicon192 from "./images/favicons/favicon-syctn-192.png";
import TagManager from "react-gtm-module";

var projectCode = process.env.REACT_APP_SITE_BRAND;

if (process.env.NODE_ENV === "production") {
  const tagManagerArgs = {
    gtmId: projectCode === "act" ? "GTM-5WRJCBJ" : "GTM-KHCLGN4",
  };
  TagManager.initialize(tagManagerArgs);
}

const rtlLanguages = ["ar", "fa", "ur", "ps"];

export default function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      delay: 250,
      easing: "ease-out-cubic",
    });
    const faviconUrl = projectCode === "act" ? actFavicon : syctnFavicon;
    const favicon192Url =
      projectCode === "act" ? actFavicon192 : syctnFavicon192;
    const injectMeta = () => {
      const faviconElement = document.getElementById("favicon");
      const favicon192Element = document.getElementById("favicon192");
      const metaDescription = document.getElementById("description");
      const hreflangElement = document.getElementById("hreflang");
      hreflangElement.href = window.location.origin + window.location.pathname;
      hreflangElement.hreflang = i18n.resolvedLanguage;
      faviconElement.href = faviconUrl;
      favicon192Element.href = favicon192Url;
      metaDescription.content = t("site-description");
      document.documentElement.dir = rtlLanguages.includes(
        i18n.resolvedLanguage
      )
        ? "rtl"
        : "ltr";
    };
    injectMeta();
  });

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          {projectCode === "act" && (
            <>
              <Route path={routes.disclaimer} element={<Disclaimer />} />
              <Route path={routesBase.disclaimer} element={<Disclaimer />} />
            </>
          )}
          <Route path={routes.root} element={<Home />} />
          <Route path={routes.home} element={<Home />} />
          <Route path={routes.faq} element={<FAQ />} />
          <Route path={routes.about} element={<About />} />
          <Route path={routesBase.root} element={<Home />} />
          <Route path={routesBase.home} element={<Home />} />
          <Route path={routesBase.faq} element={<FAQ />} />
          <Route path={routesBase.about} element={<About />} />
          {projectCode === "syctn" && (
            <>
              <Route
                path="/home.html"
                element={
                  <Navigate
                    replace
                    to={generatePath(routes.home, { locale: "en" })}
                  />
                }
              />
              <Route
                path="/about-the-tests.html"
                element={
                  <Navigate
                    replace
                    to={generatePath(routes.about, { locale: "en" })}
                  />
                }
              />
              <Route
                path="/faq.html"
                element={
                  <Navigate
                    replace
                    to={generatePath(routes.faq, { locale: "en" })}
                  />
                }
              />
              <Route
                path="/casa.html"
                element={
                  <Navigate
                    replace
                    to={generatePath(routes.home, { locale: "es" })}
                  />
                }
              />
              <Route
                path="/acerca-de-las-pruebas.html"
                element={
                  <Navigate
                    replace
                    to={generatePath(routes.about, { locale: "es" })}
                  />
                }
              />
              <Route
                path="/preguntas-frecuentes.html"
                element={
                  <Navigate
                    replace
                    to={generatePath(routes.faq, { locale: "es" })}
                  />
                }
              />
            </>
          )}
          <Route path="" element={<Home />} />
        </Route>
      </Routes>
    </Router>
  );
}
