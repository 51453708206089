import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../partials/footer";
import Nav from "../partials/nav";
import { Outlet, useParams } from "react-router-dom";
import IHealthExtensionBanner from "../partials/IHealthExtensionBanner";

const Layout = () => {
  const { i18n } = useTranslation();
  let { locale } = useParams();
  useEffect(() => {
    if (locale !== i18n.language) i18n.changeLanguage(locale);
  }, [i18n, locale]);

  return (
    <div
      className={`${
        process.env.REACT_APP_SITE_BRAND || "default"
      }-branding language-${i18n.resolvedLanguage}`}
    >
      <IHealthExtensionBanner />
      <Nav />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
