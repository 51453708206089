import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import Nav from '../partials/nav';
const docTitle = "Project ACT | Disclaimer"
const tagManagerArgs = {
  dataLayer: {
      event: 'page_view',
      page_location: document.location.href,
      page_title: docTitle
  }
}

export default function Disclaimer() {
  const { t } = useTranslation();

  TagManager.dataLayer(tagManagerArgs)
  useEffect(() => {
    document.title = docTitle; 
  }, []);


  return (
    <>
    <main className="disclaimer">
        <section className="intro">
            <h1>Project ACT Disclaimer and Limitation of Liability</h1>
            <p>{t('disclaimer')}</p>
        </section>

    </main>


</>
    
  );
}


