import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import {
  languageCodeMapping as SYCTLanguages,
  languageOrdering as SYCTLanguageOrdering,
} from "./SYCTLanguages.js";
import {
  languageCodeMapping as ACTLanguages,
  languageOrdering as ACTLanguageOrdering,
} from "./ActLanguages.js";

let resources = {};
let languageCodeMapping = {};
let order = [];
const whitelist = [];
if (process.env.REACT_APP_SITE_BRAND === "act") {
  languageCodeMapping = ACTLanguages;
  order = ACTLanguageOrdering;
  whitelist.push(...Object.values(ACTLanguages));
  whitelist.forEach(
    (languageCode) =>
      (resources[languageCode] = {
        translations: require(`./locales/act-${languageCode}.json`),
      })
  );
} else {
  languageCodeMapping = SYCTLanguages;
  order = SYCTLanguageOrdering;
  whitelist.push(...Object.values(SYCTLanguages));
  whitelist.forEach(
    (languageCode) =>
      (resources[languageCode] = {
        translations: require(`./locales/${languageCode}.json`),
      })
  );
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    fallbackLng: "en",
    resources,
    whitelist,
    detection: {
      order: [
        "path",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
      ],
      caches: [],
      lookupFromPathIndex: 1,
      checkWhitelist: true,
    },
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
  });

i18n.resolvedLanguages = whitelist;

export const languages = languageCodeMapping;
export const languageOrdering = order;
export default i18n;
