import React from "react";
import SYCTlogo from "../images/logos/syct_logo_white.svg";
import ACTlogo from "../images/logos/ProjectACT_Final_white.png";
import LanguageSwitcher from "./language-switcher";
import { useTranslation, Trans } from "react-i18next";

export default function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <footer>
      <div className="footer_right">
        <div className="funded">{t("footer-funded")}</div>
        <div className="footer-logo">
          <a
            href={"/" + i18n.resolvedLanguage}
            rel="home"
            className="eng_footer"
          >
            <img
              src={
                process.env.REACT_APP_SITE_BRAND === "act" ? ACTlogo : SYCTlogo
              }
              alt="home button"
            />
          </a>
        </div>
      </div>
      <div className="footer_left">
        <LanguageSwitcher />
        <div className="disclaimer">
          <a href={"/disclaimer/" + i18n.resolvedLanguage}>
            Disclaimer and Limitation of Liability
          </a>
        </div>
        <div className="accessibility">
          <Trans i18nKey="footer-support">
            For accessibility concerns, please
            <a href="mailto:syct-orders@careevolution.com">notify us</a>
          </Trans>
        </div>
        <div className="copyright">
          <small>© {(new Date().getFullYear())} CareEvolution, LLC</small>
        </div>
      </div>
    </footer>
  );
}
