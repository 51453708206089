import React, { useState, useEffect } from "react";
import SearchModule from "../partials/search-module";
// import ComingSoon from '../partials/coming-soon';
import dig_assist_icon from "../images/shared/dig_assist_icon.svg";
import easy from "../images/shared/YES-ICONS_ICON-EASY.svg";
import fast from "../images/shared/YES-ICONS_ICON-FAST.svg";
import trusted from "../images/shared/YES-ICONS_ICON-TRUSTED.svg";
import woman_testing from "../images/shared/woman-testing-at-home.svg";

import { useTranslation, Trans } from "react-i18next";

import TagManager from "react-gtm-module";
export default function About() {
  const { t, i18n } = useTranslation();

  const tagManagerArgs = {
    dataLayer: {
      event: "page_view",
      page_location: document.location.href,
      page_title: t("about-meta-title"),
    },
  };

  var daUrl =
    "https://safercovid.org/mytest/index.html?utm_source=website&lang=" +
    i18n.resolvedLanguage;
  var cdcSelfTestUrl =
    "https://www.cdc.gov/coronavirus/2019-ncov/testing/self-testing.html";
  if (i18n.resolvedLanguage == "es") {
    cdcSelfTestUrl =
      "https://espanol.cdc.gov/coronavirus/2019-ncov/testing/self-testing.html";
  } else if (i18n.resolvedLanguage == "en") {
    cdcSelfTestUrl =
      "https://www.cdc.gov/coronavirus/2019-ncov/testing/self-testing.html";
  }

  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    document.title = t("about-meta-title");
  }, []);

  const [posOpen, setPosOpen] = useState(false);
  const [negOpen, setNegOpen] = useState(false);

  const handlePosToggle = () => {
    setPosOpen((prev) => !prev);
    if (negOpen === true) {
      setNegOpen(false);
    }
  };
  const handleNegToggle = () => {
    setNegOpen((prev) => !prev);
    if (posOpen === true) {
      setPosOpen(false);
    }
  };

  return (
    <main>
      <section className="col-2-md about-the-tests-hero" data-aos="zoom-y-out">
        <div className="">
          <img src={woman_testing} alt="testing chair" />
        </div>
        <div className="">
          <h2>
            <Trans i18nKey="about-hero-h">
              Learn your COVID-19 test results privately at home in
              <strong>minutes</strong>
            </Trans>
          </h2>
          <div className="col-2-sm">
            <div className="instructions-button dig-assist">
              <a href={daUrl} target="_blank" rel="noreferrer">
                <img src={dig_assist_icon} alt="access the digital assistant" />
                <p>
                  <strong>{t("dig-assist-button")}</strong>
                </p>
              </a>
            </div>
            <div className="">
              <p>{t("about-hero-p")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="highlighted" data-aos="zoom-y-out">
        <h2 className="center" style={{ paddingBottom: "2rem" }}>
          <strong>{t("about-kits-h")}</strong>
        </h2>
        <div className="col-3-xs">
          <div className="icon-card">
            <div className="icon-circle-wrap">
              <img src={easy} alt="" width="300" height="300" />
            </div>
            <h3>{t("about-kits-01h")}</h3>
            <p>{t("about-kits-01p")}</p>
          </div>
          <div className="icon-card">
            <div className="icon-circle-wrap">
              <img src={fast} alt="" width="300" height="300" />
            </div>
            <h3>{t("about-kits-02h")}</h3>
            <p>{t("about-kits-02p")}</p>
          </div>
          <div className="icon-card">
            <div className="icon-circle-wrap">
              <img src={trusted} alt="" width="300" height="300" />
            </div>
            <h3>{t("about-kits-03h")}</h3>
            <p>{t("about-kits-03p")}</p>
          </div>
        </div>
      </section>
      {/* <section className="when-to-test" data-aos="zoom-y-out" >
            <h2>{t('about-when-h')}</h2>
            <p style={{paddingBottom:'1rem'}}>
                <Trans i18nKey="about-when-p">
                <strong>Don't wait to start testing</strong>. People who <strong>do not have symptoms</strong> can still spread COVID-19.
                </Trans>
            </p>

            <div className="col-2-sm">
                <div className="test_now">
                    <h3 className="center">{t('about-when-now-h')}</h3>
                    <p className="center"><img src={test_now} alt="test now"/></p>
                </div>
                <div className="test_three">
                    <h3 className="center">{t('about-when-later-h')}</h3>
                    <img src={test_three} alt="test 3 to five days after exposure"/>
                </div>
            </div>
        </section> */}

      <section className="when-to-test center" data-aos="zoom-y-out">
        <h2>{t("about-when-h")}</h2>
        <p style={{ paddingBottom: "1rem" }}>
          <Trans i18nKey="about-when-p">
            <strong>Don't wait to start testing</strong>. People who
            <strong>do not have symptoms</strong> can still spread COVID-19.
          </Trans>
        </p>

        <div className="col-2-sm">
          <div className="test_now">
            <h3>{t("about-when-now-h")}</h3>
            <p>
              <span>{t("test")}</span>
              <span>{t("now")}</span>
            </p>
          </div>
          <div className="test_three">
            <h3>{t("about-when-later-h")}</h3>
            <p>
              <span>{t("test")}</span>
              <span>3-5</span>
              <span>{t("days-after-exposure")}</span>
            </p>
          </div>
        </div>
      </section>

      <section className="highlighted" data-aos="zoom-y-out">
        <div className="center">
          <h2>{t("test-instructions")}</h2>
          <p>{t("test-instructions-p")}</p>
        </div>
        <div className="col-2-sm instructions">
          <div>
            <div className="instructions-button dig-assist">
              <a href={daUrl} target="_blank" rel="noreferrer">
                <img src={dig_assist_icon} alt="access the digital assistant" />
                <p>
                  <strong>{t("dig-assist-button")}</strong>
                </p>
              </a>
            </div>
            <p className="center">{t("about-instructions-01")}</p>
          </div>
          <div>
            <div className="instructions-button">
              <a href={cdcSelfTestUrl} target="_blank" rel="noreferrer">
                <i className="fas fa-laptop-medical"></i>
                <p>
                  <strong>{t("self-testing-button")}</strong>
                </p>
              </a>
            </div>
            <p className="center">{t("about-instructions-02")}</p>
          </div>
        </div>
      </section>

      <section className="positive-negative" data-aos="zoom-y-out">
        <div className="center">
          <h2>{t("if-results")}</h2>
          <p>
            <strong>({t("choose-one")})</strong>
          </p>
          <p>{t("test-instructions-p")}</p>
        </div>
        <div className="toggles">
          <div
            id="positive-toggle"
            onClick={handlePosToggle}
            className={`${negOpen ? " inactive" : "active"}`}
          >
            <i className="fas fa-plus-circle"></i>
            {t("positive")}
          </div>
          <div
            id="negative-toggle"
            onClick={handleNegToggle}
            className={`${posOpen ? " inactive" : "active"}`}
          >
            <i className="fas fa-minus-circle"></i>
            {t("negative")}
          </div>
        </div>
        <div id="positive" className={`${posOpen ? " toggled" : ""}`}>
          <h4>{t("positive-01h")}</h4>
          <p>{t("positive-01p")}</p>
          <h4>{t("positive-02h")}</h4>
          <p>
            <span dangerouslySetInnerHTML={{ __html: t("positive-02p") }} />
          </p>
          <h4>{t("positive-03h")}</h4>
          <p>
            <span dangerouslySetInnerHTML={{ __html: t("positive-03p") }} />
          </p>
        </div>
        <div id="negative" className={`${negOpen ? " toggled" : ""}`}>
          <h4>{t("negative-01h")}</h4>
          <p>{t("negative-01p")}</p>
          <h4>{t("negative-02h")}</h4>
          <p>
            <span dangerouslySetInnerHTML={{ __html: t("negative-02p") }} />
          </p>
          <h4>{t("negative-03h")}</h4>
          <p>{t("negative-03p")}</p>
          <h4>{t("negative-04h")}</h4>
          <p>
            <span dangerouslySetInnerHTML={{ __html: t("negative-04p") }} />
          </p>
        </div>
      </section>

      <div className="highlighted" data-aos="zoom-y-out">
        <SearchModule />
        {/* <ComingSoon/> */}
      </div>
    </main>
  );
}
