import React from "react";
import { useTranslation, Trans } from "react-i18next";

const IHealthExtensionBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="ihealth-expiration">
      {process.env.REACT_APP_SITE_BRAND === "act" ? (
        <React.Fragment>
          <h3 className="ihealth-header">{t("ihealth-expiration-h")}</h3>
          <p>
            <Trans i18nKey="ihealth-expiration-p">
              As of July 8th, 2022, the FDA extended the shelf-life of iHealth COVID-19 Antigen Rapid Tests to 15 months. Please
              <a
                href="https://ihealthlabs.com/pages/news#expiration"
                target="_blank"
                rel="noreferrer"
              >
                click here
              </a>
              for details or to look up new expiration dates by lot numbers.
            </Trans>
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h3 className="ihealth-header">{t("expiration-h")}</h3>
          <p>
            {t("expiration-p")}<br></br>
            <a
              href="https://ihealthlabs.com/pages/news#expiration"
              target="_blank"
              rel="noreferrer"
            >
              {t("expiration-link-ihealth")}
            </a>
            {" | "}
            <a
              href="https://www.fda.gov/media/158003/download"
              target="_blank"
              rel="noreferrer"
            >
              {t("expiration-link-abbott")}
            </a>
          </p>
        </React.Fragment>
      )}
    </div>
  );
};

export default IHealthExtensionBanner;